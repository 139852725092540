import React, { FC } from "react";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Tooltip from "@components/ui/Tooltip";

type Props = {
  tooltipText: string;
  onClick: () => void;
  active?: boolean;
  height?: "32px" | "40px";
  borderRadius?: "8px" | "40px";
};

const TranslateButtonIcon: FC<Props> = ({
  tooltipText,
  onClick,
  active,
  height,
  borderRadius,
}) => {
  return (
    <Tooltip
      text={active ? "" : tooltipText}
      placement="bottom"
      arrow
      horizontalOffset={20}
      verticalOffset={4}
    >
      <Button
        variant={active ? "Primary" : "Outline"}
        height={height}
        leftIcon="language"
        borderRadius={borderRadius}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default TranslateButtonIcon;
