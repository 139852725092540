import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { TrainingResourceFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTrainingResourceMutationVariables = Types.Exact<{
  input: Types.CreateTrainingResourceInput;
}>;


export type CreateTrainingResourceMutation = { __typename?: 'Mutation', createTrainingResourceV2: { __typename?: 'TrainingResourceMutationResult', success: boolean, trainingResource?: { __typename?: 'TrainingResource', id: string, resourceId: string, media: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null }, libraryItem: { __typename?: 'LibraryItem', id: string, lastEditedAt?: string | null, openedAt?: string | null, archivedAt?: string | null } } | null } };

export type UpdateTrainingResourceMutationVariables = Types.Exact<{
  trainingResourceId: Types.Scalars['String'];
  input: Types.UpdateTrainingResourceInput;
}>;


export type UpdateTrainingResourceMutation = { __typename?: 'Mutation', updateTrainingResource: { __typename?: 'TrainingResourceMutationResult', success: boolean, trainingResource?: { __typename?: 'TrainingResource', id: string, resourceId: string, media: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null }, libraryItem: { __typename?: 'LibraryItem', id: string, lastEditedAt?: string | null, openedAt?: string | null, archivedAt?: string | null } } | null } };


export const CreateTrainingResourceDocument = gql`
    mutation createTrainingResource($input: CreateTrainingResourceInput!) {
  createTrainingResourceV2(input: $input) {
    success
    trainingResource {
      ...TrainingResource
    }
  }
}
    ${TrainingResourceFragmentDoc}`;
export type CreateTrainingResourceMutationFn = Apollo.MutationFunction<CreateTrainingResourceMutation, CreateTrainingResourceMutationVariables>;

/**
 * __useCreateTrainingResourceMutation__
 *
 * To run a mutation, you first call `useCreateTrainingResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingResourceMutation, { data, loading, error }] = useCreateTrainingResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrainingResourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrainingResourceMutation, CreateTrainingResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrainingResourceMutation, CreateTrainingResourceMutationVariables>(CreateTrainingResourceDocument, options);
      }
export type CreateTrainingResourceMutationHookResult = ReturnType<typeof useCreateTrainingResourceMutation>;
export type CreateTrainingResourceMutationResult = Apollo.MutationResult<CreateTrainingResourceMutation>;
export type CreateTrainingResourceMutationOptions = Apollo.BaseMutationOptions<CreateTrainingResourceMutation, CreateTrainingResourceMutationVariables>;
export const UpdateTrainingResourceDocument = gql`
    mutation updateTrainingResource($trainingResourceId: String!, $input: UpdateTrainingResourceInput!) {
  updateTrainingResource(trainingResourceId: $trainingResourceId, input: $input) {
    success
    trainingResource {
      ...TrainingResource
    }
  }
}
    ${TrainingResourceFragmentDoc}`;
export type UpdateTrainingResourceMutationFn = Apollo.MutationFunction<UpdateTrainingResourceMutation, UpdateTrainingResourceMutationVariables>;

/**
 * __useUpdateTrainingResourceMutation__
 *
 * To run a mutation, you first call `useUpdateTrainingResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrainingResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrainingResourceMutation, { data, loading, error }] = useUpdateTrainingResourceMutation({
 *   variables: {
 *      trainingResourceId: // value for 'trainingResourceId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrainingResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrainingResourceMutation, UpdateTrainingResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrainingResourceMutation, UpdateTrainingResourceMutationVariables>(UpdateTrainingResourceDocument, options);
      }
export type UpdateTrainingResourceMutationHookResult = ReturnType<typeof useUpdateTrainingResourceMutation>;
export type UpdateTrainingResourceMutationResult = Apollo.MutationResult<UpdateTrainingResourceMutation>;
export type UpdateTrainingResourceMutationOptions = Apollo.BaseMutationOptions<UpdateTrainingResourceMutation, UpdateTrainingResourceMutationVariables>;