import { TranslationSetInput } from "@src/types.generated";
import React, { FC } from "react";
import { TranslationItemInput } from "@components/modals/translationSetModals/TranslationSetsModal";
import TranslationSetTextItem from "@components/modals/translationSetModals/TranslationSetTextItem";
import TranslationSetFileItem from "@components/modals/translationSetModals/TranslationSetFileItem";

type Props = {
  translationModeEnabled: boolean;
  selectedTranslateLanguage: Language;
  item: TranslationItemInput;
  onChange: (ts: TranslationSetInput) => void;
  hasValidationError: boolean;
  removeValidationError: () => void;
};

const TranslationSetItem: FC<Props> = ({
  translationModeEnabled,
  selectedTranslateLanguage,
  item,
  onChange,
  hasValidationError,
  removeValidationError,
}) => {
  const { label, ts, isFile, isRequired, disableFileDelete } = item;
  if (isFile) {
    return (
      <TranslationSetFileItem
        translationModeEnabled={translationModeEnabled}
        selectedTranslateLanguage={selectedTranslateLanguage}
        label={label}
        ts={ts}
        onChange={onChange}
        hasValidationError={hasValidationError}
        removeValidationError={removeValidationError}
        disableDelete={disableFileDelete}
      />
    );
  }
  return (
    <TranslationSetTextItem
      translationModeEnabled={translationModeEnabled}
      selectedTranslateLanguage={selectedTranslateLanguage}
      isRequired={isRequired}
      label={label}
      ts={ts}
      onChange={onChange}
      hasValidationError={hasValidationError}
      removeValidationError={removeValidationError}
    />
  );
};

export default TranslationSetItem;
