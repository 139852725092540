import Modal from "@components/modals/Modal";
import TranslationSetItem from "@components/modals/translationSetModals/TranslationSetItem";
import TranslationModeToggle from "@components/ui/translations/TranslationModeToggle";
import TranslationBanner from "@src/deprecatedDesignSystem/components/TranslationBanner";
import { useAvailableLanguages } from "@hooks/useAvailableLanguages";
import { useModal } from "@hooks/useModal";
import { TranslationSetObjectInput } from "@src/types.generated";
import { StyleSheet, css } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import { ReactElement, useCallback, useMemo, useState } from "react";

export type TranslationItemInput = {
  index: number;
  ts: TranslationSetObjectInput;
  label?: string;
  isFile?: boolean;
  isRequired?: boolean;
  disableFileDelete?: boolean;
  disableCloseTranslationMode?: boolean;
};

type Props<T extends Record<string, TranslationItemInput>> = {
  items: T;
  translationModeEnabled?: boolean;
  disableModeToggle?: boolean;
  title: string;
  onSave: (items: T) => void;
};

function TranslationSetsModal<T extends Record<string, TranslationItemInput>>({
  items: initialItems,
  onSave,
  title,
  translationModeEnabled: initialTranslationModeEnabled = false,
  disableModeToggle,
}: Props<T>): ReactElement {
  const [items, setItems] = useState(initialItems);
  const [validationErrorItems, setValidationErrorItems] = useState(
    new Set<string>(),
  );
  const [translationModeEnabled, setTranslationModeEnabled] = useState(
    initialTranslationModeEnabled,
  );
  const { mostPopularNonEnglishLanguage, allNonEnglishLanguages } =
    useAvailableLanguages();
  const [selectedTranslateLanguage, setSelectedTranslateLanguage] = useState(
    mostPopularNonEnglishLanguage,
  );
  const { closeModal } = useModal();
  const orderedItems: [string, TranslationItemInput][] = useMemo(() => {
    return Object.entries(items).sort((a, b) => a[1].index - b[1].index);
  }, [items]);
  const getInvalidItemKeys = useCallback(
    (items: [string, TranslationItemInput][]): string[] => {
      return items
        .filter((item) => item[1].isRequired && !item[1].ts.en)
        .map((item) => item[0]);
    },
    [],
  );
  return (
    <Modal
      className={css(styles.modal)}
      title={title}
      footerClassName={css(styles.modalFooter)}
      subFooterContent={
        disableModeToggle ? undefined : allNonEnglishLanguages.length > 0 ? (
          <div className={css(styles.modalFooterContent)}>
            <TranslationModeToggle
              translationModeEnabled={translationModeEnabled}
              setTranslationModeEnabled={setTranslationModeEnabled}
              buttonHeight="40px"
              buttonBorderRadius="40px"
            />
          </div>
        ) : undefined
      }
      cancelButtonProps={{
        copy: "Cancel",
        onClick: closeModal,
      }}
      confirmButtonProps={{
        copy: "Save",
        onClick: () => {
          const invalidKeys = getInvalidItemKeys(orderedItems);
          if (invalidKeys.length > 0) {
            setValidationErrorItems(new Set(invalidKeys));
          } else {
            onSave(items);
          }
        },
      }}
    >
      <AnimatePresence initial={false}>
        <motion.div
          animate={
            translationModeEnabled
              ? "translationModeEnabled"
              : "translationModeDisabled"
          }
          variants={{
            translationModeEnabled: {
              width: 800,
              transition: {
                delay: 0,
                duration: 0.3,
              },
            },
            translationModeDisabled: {
              width: 500,
              transition: {
                delay: 0,
                duration: 0.3,
              },
            },
          }}
        >
          <TranslationBanner
            translationModeEnabled={translationModeEnabled}
            selectedLanguage={selectedTranslateLanguage}
            onLanguageChange={setSelectedTranslateLanguage}
            onClose={
              disableModeToggle
                ? undefined
                : () => setTranslationModeEnabled(false)
            }
          />
          <div className={css(styles.modalContent)}>
            {orderedItems.map(([key, item]) => (
              <TranslationSetItem
                key={key}
                item={item}
                translationModeEnabled={translationModeEnabled}
                selectedTranslateLanguage={selectedTranslateLanguage}
                hasValidationError={validationErrorItems.has(key)}
                removeValidationError={() => {
                  if (validationErrorItems.has(key)) {
                    const newValidationErrorItems = new Set(
                      validationErrorItems,
                    );
                    newValidationErrorItems.delete(key);
                    setValidationErrorItems(new Set(newValidationErrorItems));
                  }
                }}
                onChange={(ts) => {
                  setItems({
                    ...items,
                    [key]: {
                      ...item,
                      ts,
                    },
                  });
                }}
              />
            ))}
          </div>
        </motion.div>
      </AnimatePresence>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: { maxWidth: "unset" },
  modalContent: {
    margin: 24,
    marginTop: 32,
    marginBottom: 48,
  },
  headerButtons: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: 0,
  },
  translateModeToggleContainer: {
    position: "absolute",
    right: 32,
  },
  modalFooter: {
    borderTop: "none",
  },
  modalFooterContent: {
    marginRight: "auto",
  },
});

export default TranslationSetsModal;
