import React, { FC } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  text?: string;
  isRequired?: boolean;
  hasValidationError?: boolean;
};

const Label: FC<Props> = ({ text, isRequired, hasValidationError }) => {
  return (
    <div className={css(styles.label)}>
      {text}{" "}
      {text && !isRequired && (
        <span className={css(styles.optionalLabel)}>(Optional)</span>
      )}
      {text && hasValidationError && (
        <span className={css(styles.errorLabel)}>*Required</span>
      )}
    </div>
  );
};

export default Label;

const styles = StyleSheet.create({
  label: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 8,
    height: 18,
  },
  optionalLabel: {
    fontSize: 13,
    color: deprecatedColors.outline,
    fontWeight: 400,
    marginLeft: 2,
  },
  errorLabel: {
    fontSize: 13,
    color: deprecatedTones.red5,
    fontWeight: 400,
    marginLeft: 2,
  },
});
