import UploadFileSection from "@components/modals/translationSetModals/UploadFileSection";
import MediaPreview from "@components/ui/MediaPreview";
import { FC } from "react";

type UploadFileOrPreviewSectionProps = {
  mediaUrl?: string | null;
  setMediaUrl: (url?: string) => void;
  disableDelete?: boolean;
};

export const UploadFileOrPreviewSection: FC<
  UploadFileOrPreviewSectionProps
> = ({ mediaUrl, setMediaUrl, disableDelete = false }) => {
  if (mediaUrl) {
    return (
      <MediaPreview
        contentId={mediaUrl}
        name={mediaUrl}
        url={mediaUrl}
        onRemoveMedia={
          disableDelete
            ? undefined
            : () => {
                setMediaUrl(undefined);
              }
        }
        showLink={true}
      />
    );
  } else {
    return (
      <UploadFileSection
        setMediaUrl={(url) => {
          setMediaUrl(url);
        }}
      />
    );
  }
};

export default UploadFileOrPreviewSection;
