import { TranslationSetInput } from "@src/types.generated";
import React, { FC } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import UploadFileOrPreviewSection from "@components/modals/translationSetModals/UploadFileOrPreviewSection";
import Label from "@components/modals/translationSetModals/Label";
import { useTranslation } from "@utils/translationSets";

type Props = {
  translationModeEnabled: boolean;
  selectedTranslateLanguage: Language;
  label?: string;
  ts: TranslationSetInput;
  onChange: (ts: TranslationSetInput) => void;
  hasValidationError: boolean;
  removeValidationError: () => void;
  disableDelete?: boolean;
};

const TranslationSetFileItem: FC<Props> = ({
  translationModeEnabled,
  selectedTranslateLanguage,
  label,
  ts,
  onChange,
  hasValidationError,
  removeValidationError,
  disableDelete,
}) => {
  const translatedTs = useTranslation(ts, selectedTranslateLanguage).text;
  return (
    <div className={css(styles.translationSetFileItemContainer)}>
      {!translationModeEnabled && (
        <div className={css(styles.nonTranslationContent)}>
          {label && (
            <Label
              text={label}
              isRequired={true}
              hasValidationError={hasValidationError}
            />
          )}
          <UploadFileOrPreviewSection
            mediaUrl={ts.en}
            setMediaUrl={(val) => {
              removeValidationError();
              onChange({
                ...ts,
                en: val || "",
              });
            }}
            disableDelete={disableDelete}
          />
        </div>
      )}
      {translationModeEnabled && (
        <>
          <div className={css(styles.leftCol)}>
            {label && (
              <Label
                text={label}
                isRequired={true}
                hasValidationError={hasValidationError}
              />
            )}
            <UploadFileOrPreviewSection
              mediaUrl={ts.en}
              setMediaUrl={(val) => {
                removeValidationError();
                onChange({
                  ...ts,
                  en: val || "",
                });
              }}
              disableDelete={disableDelete}
            />
          </div>
          <div className={css(styles.rightCol)}>
            {label && <Label />}
            <UploadFileOrPreviewSection
              mediaUrl={translatedTs}
              setMediaUrl={(val) => {
                onChange({
                  ...ts,
                  [selectedTranslateLanguage]: val || "",
                });
              }}
              disableDelete={disableDelete}
            />
          </div>
        </>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  translationSetFileItemContainer: {
    marginTop: 24,
    display: "flex",
  },
  nonTranslationContent: {
    width: "100%",
  },
  leftCol: {
    paddingRight: 12,
    flex: 1,
  },
  rightCol: {
    paddingLeft: 12,
    flex: 1,
  },
  headerContent: {
    display: "flex",
    padding: "12px 12px 12px 24px",
    minHeight: 56,
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  title: {
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 16,
    fontWeight: 500,
  },
});

export default TranslationSetFileItem;
