import { useModal } from "@hooks/useModal";
import { MediaFragment } from "@src/fragments.generated";
import gql from "graphql-tag";
import { FC, useCallback, useMemo } from "react";
import TranslationSetsModal from "@components/modals/translationSetModals/TranslationSetsModal";
import { useToast } from "@hooks/useToast";
import { useTracking } from "@hooks/useTracking";
import { launchConfetti } from "@utils/confetti";
import { useRouter } from "next/router";
import { getFileDetailRoute } from "../contentLibrary/utils";
import { emptyTranslationSet } from "@utils/prompts";
import { CONTENT_LIBRARY_REFETCH_QUERIES } from "@src/contexts/TagContext/TagContext";
import {
  useCreateTrainingResourceMutation,
  useUpdateTrainingResourceMutation,
} from "./TrainingResourceModal.generated";
import { GraphqlOperations } from "@src/types.generated";

type Props = {
  file?: {
    id: string;
    media: MediaFragment;
  };
  initialMedia?: MediaFragment;
  goToFileDetailOnCreation?: boolean;
  onFileUpdate?: (updatedMedia: MediaFragment) => void;
};

const TrainingResourceModal: FC<Props> = ({
  file,
  initialMedia,
  goToFileDetailOnCreation = true,
  onFileUpdate,
}) => {
  const { closeModal } = useModal();
  const { trackEvent } = useTracking();
  const router = useRouter();
  const goToFileDetail = useCallback(
    (fileId: string) => {
      router.push(getFileDetailRoute(fileId));
    },
    [router],
  );
  const { addErrorToast } = useToast();
  const media = useMemo(
    () => initialMedia || file?.media,
    [initialMedia, file],
  );
  const name = useMemo(
    () => (media?.name ? media.name : emptyTranslationSet()),
    [media?.name],
  );
  const description = useMemo(
    () => (media?.description ? media.description : emptyTranslationSet()),
    [media?.description],
  );
  const mediaUrls = useMemo(
    () =>
      media?.mediaUrls
        ? media.mediaUrls
        : emptyTranslationSet({ translationsDisabled: true }),
    [media],
  );
  const [createTrainingResourceMutation] = useCreateTrainingResourceMutation({
    refetchQueries: [...CONTENT_LIBRARY_REFETCH_QUERIES],
    onCompleted(data) {
      if (!data.createTrainingResourceV2.success) {
        addErrorToast({
          data,
          callsite: "create_training_resource_file",
        });
      } else {
        trackEvent("create_training_resource_file", {
          id: data.createTrainingResourceV2.trainingResource?.id,
        });
        closeModal();
        launchConfetti();
        const createdTrainingResourceId =
          data.createTrainingResourceV2.trainingResource?.resourceId;
        if (createdTrainingResourceId && goToFileDetailOnCreation) {
          goToFileDetail(createdTrainingResourceId);
        }
      }
    },
    onError: (error) => {
      addErrorToast({
        error,
        callsite: "create_training_resource_file",
      });
    },
  });
  const [updateTrainingResourceMutation] = useUpdateTrainingResourceMutation({
    refetchQueries: [GraphqlOperations.Query.FileDetailOverview],
    onCompleted(data) {
      if (!data.updateTrainingResource.success) {
        addErrorToast({
          data,
          callsite: "update_training_resource_file",
        });
      } else {
        trackEvent("update_training_resource_file", {
          id: data.updateTrainingResource.trainingResource?.id,
        });
        if (data.updateTrainingResource.trainingResource) {
          onFileUpdate?.(data.updateTrainingResource.trainingResource.media);
        }
        closeModal();
        launchConfetti();
      }
    },
    onError: (error) => {
      addErrorToast({
        error,
        callsite: "update_training_resource_file",
      });
    },
  });
  const title = useMemo(() => {
    if (file) {
      return "Edit Resource";
    } else if (initialMedia) {
      return "Save to Library";
    }
    return "Upload Resource";
  }, [file, initialMedia]);
  return (
    <TranslationSetsModal
      items={{
        mediaUrls: {
          index: 0,
          label: "Resource",
          ts: mediaUrls,
          isFile: true,
          isRequired: true,
          disableFileDelete: !file && !!initialMedia,
        },
        name: { index: 1, label: "Name", ts: name, isRequired: true },
        description: {
          index: 2,
          label: "Description",
          ts: description,
        },
      }}
      title={title}
      onSave={({ name, mediaUrls, description }) => {
        if (media && file) {
          updateTrainingResourceMutation({
            variables: {
              trainingResourceId: file.id,
              input: {
                mediaInput: {
                  name: name.ts,
                  mediaUrls: mediaUrls.ts,
                  description: description.ts,
                },
              },
            },
          });
        } else {
          createTrainingResourceMutation({
            variables: {
              input: {
                mediaInput: {
                  name: name.ts,
                  mediaUrls: mediaUrls.ts,
                  description: description.ts,
                },
              },
            },
          });
        }
      }}
    />
  );
};

export default TrainingResourceModal;

gql`
  mutation createTrainingResource($input: CreateTrainingResourceInput!) {
    createTrainingResourceV2(input: $input) {
      success
      trainingResource {
        ...TrainingResource
      }
    }
  }
  mutation updateTrainingResource(
    $trainingResourceId: String!
    $input: UpdateTrainingResourceInput!
  ) {
    updateTrainingResource(
      trainingResourceId: $trainingResourceId
      input: $input
    ) {
      success
      trainingResource {
        ...TrainingResource
      }
    }
  }
`;
