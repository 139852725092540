import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import VideoPlayer from "@src/deprecatedDesignSystem/components/VideoPlayer";
import { getLinkType, LinkType, linkTypeCopy } from "@utils/files";
import gql from "graphql-tag";
import React, { FC, useState } from "react";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Button from "@src/deprecatedDesignSystem/components/Button";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import { css, StyleSheet } from "aphrodite";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

type Props = {
  contentId: string | number;
  url: string;
  name?: string;
  onRemoveMedia?: () => void;
  showLink?: boolean;
  containerClassName?: string;
};

const MediaPreview: FC<Props> = ({
  url,
  name = "",
  onRemoveMedia,
  showLink = false,
  containerClassName,
  contentId,
}) => {
  const linkType = getLinkType(url);
  const [videoPreviewVisible, setVideoPreviewVisible] = useState(false);
  const isVideoOrMuxVideo = [LinkType.MUX_VIDEO, LinkType.VIDEO].includes(
    linkType,
  );
  return (
    <div className={containerClassName}>
      <AutoLayout
        padding="8px"
        alignmentVertical="center"
        styleDeclaration={styles.mediaContainer}
      >
        <AutoLayout
          flex={1}
          alignmentVertical="center"
          marginRight="auto"
          styleDeclaration={styles.mediaRow}
        >
          <ContentAvatar
            coverImage={{ imageUrls: { original: url } }}
            contentType={"Resource"}
            size={"56px"}
            defaultBackgroundHashKey={contentId}
          />
          <div style={{ marginLeft: "10px" }}>
            <div className={css(styles.mediaName)}>{name}</div>
            <div>{linkTypeCopy[getLinkType(url)]}</div>
            {showLink && !isVideoOrMuxVideo && (
              <div
                className={css(styles.mediaLink)}
                onClick={() => window.open(url)}
              >
                <span className={css(styles.span)}>Open file</span>
                <DeprecatedIcon
                  type="link"
                  color={deprecatedTones.green9Alpha}
                />
              </div>
            )}
            {showLink && isVideoOrMuxVideo && (
              <div
                className={css(styles.mediaLink)}
                onClick={() => {
                  setVideoPreviewVisible(!videoPreviewVisible);
                }}
              >
                <span className={css(styles.span)}>
                  {videoPreviewVisible ? "Hide" : "Play"} Video
                </span>
              </div>
            )}
          </div>
        </AutoLayout>
        {onRemoveMedia && (
          <Button
            variant="Outline"
            leftIcon="trash-2"
            onClick={() => onRemoveMedia()}
          />
        )}
      </AutoLayout>
      {videoPreviewVisible && isVideoOrMuxVideo && (
        <VideoPlayer
          src={url}
          controls
          style={{ width: "100%", marginTop: 20, borderRadius: 8 }}
        />
      )}
    </div>
  );
};

export default MediaPreview;

const styles = StyleSheet.create({
  mediaContainer: {
    borderRadius: "8px",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.03)",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  mediaName: {
    fontSize: "14px",
    fontWeight: 500,
    color: deprecatedTones.gray11,
    lineHeight: "24px",
    whiteSpace: "nowrap",
    overflowX: "hidden",
    maxWidth: "250px",
    textOverflow: "ellipsis",
  },
  mediaRow: {
    color: deprecatedColors.outline,
    fontSize: "13px",
  },
  mediaLink: {
    marginTop: "3px",
    cursor: "pointer",
    color: deprecatedColors.primary,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  span: {
    marginRight: "3px",
  },
});

gql`
  query OpenGraphData($url: String!) {
    OpenGraphData(url: $url)
  }
`;
