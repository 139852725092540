import { TranslationSetInput } from "@src/types.generated";
import React, { FC } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Label from "@components/modals/translationSetModals/Label";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { updateTranslationSet, useTranslation } from "@utils/translationSets";

type Props = {
  translationModeEnabled: boolean;
  selectedTranslateLanguage: Language;
  isRequired?: boolean;
  label?: string;
  ts: TranslationSetInput;
  onChange: (ts: TranslationSetInput) => void;
  hasValidationError: boolean;
  removeValidationError: () => void;
};

const TranslationSetTextItem: FC<Props> = ({
  translationModeEnabled,
  selectedTranslateLanguage,
  label,
  isRequired,
  ts,
  onChange,
  hasValidationError,
  removeValidationError,
}) => {
  const translatedTs = useTranslation(ts, selectedTranslateLanguage).text;
  return (
    <div className={css(styles.translationSetItemTextContainer)}>
      {!translationModeEnabled && (
        <div className={css(styles.nonTranslationContent)}>
          {label && (
            <Label
              text={label}
              isRequired={isRequired}
              hasValidationError={hasValidationError}
            />
          )}

          <TextField
            text={ts.en}
            onTextChange={(val) => {
              removeValidationError();
              onChange(
                updateTranslationSet({
                  ts: ts,
                  lang: "en",
                  text: val,
                }),
              );
            }}
          />
        </div>
      )}
      {translationModeEnabled && (
        <>
          <div className={css(styles.leftCol)}>
            {label && (
              <Label
                text={label}
                isRequired={isRequired}
                hasValidationError={hasValidationError}
              />
            )}
            <TextField
              text={ts.en || ""}
              onTextChange={(val) => {
                removeValidationError();
                onChange(
                  updateTranslationSet({
                    ts: ts,
                    lang: "en",
                    text: val,
                  }),
                );
              }}
            />
          </div>
          <div className={css(styles.rightCol)}>
            {label && <Label />}
            <TextField
              text={translatedTs || ""}
              onTextChange={(val) => {
                onChange(
                  updateTranslationSet({
                    ts: ts,
                    lang: selectedTranslateLanguage,
                    text: val,
                  }),
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  translationSetItemTextContainer: {
    marginTop: 24,
    display: "flex",
  },
  nonTranslationContent: {
    width: "100%",
  },
  leftCol: {
    paddingRight: 12,
    flex: 1,
  },
  rightCol: {
    paddingLeft: 12,
    flex: 1,
  },
  headerContent: {
    display: "flex",
    padding: "12px 12px 12px 24px",
    minHeight: 56,
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  title: {
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 16,
    fontWeight: 500,
  },
});

export default TranslationSetTextItem;
