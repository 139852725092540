import Button from "@src/deprecatedDesignSystem/components/Button";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { MediaFragment, useFileUpload } from "@hooks/useFileUpload";
import { useFileListUpload } from "@src/hooks/useFileListUpload";
import FileUploadIllustration from "@src/illustrations/FileUploadIllustration";
import { pluralize } from "@src/utils/strings";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MiscStyles from "@src/deprecatedDesignSystem/styles/MiscStyles";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { FC, useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import useClickOutside from "@hooks/useClickOutside";
import { useToast } from "@hooks/useToast";
import isURL from "validator/lib/isURL";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
// @ts-ignore
import RelativePortal from "react-relative-portal";

type UploadFileSectionProps = {
  setMediaUrl: (url: string) => void;
  onFileSelected?: (file: File) => void;
  description?: string;
  styleDeclaration?: StyleDeclaration;
  acceptTypes?: { [key: string]: string[] };
  setMediaListUrls?: (urls: string[]) => void;
};

const UploadFileSection: FC<UploadFileSectionProps> = ({
  setMediaUrl,
  description,
  styleDeclaration,
  acceptTypes,
  setMediaListUrls,
}) => {
  const [onFileSelected, { loading }] = useFileUpload((e: MediaFragment) => {
    setMediaUrl(e.url);
  });
  const setMediaList = useCallback(
    (media: MediaFragment[]) =>
      setMediaListUrls && setMediaListUrls(media.map((m) => m.url)),
    [setMediaListUrls],
  );
  const [uploadList, { loading: listLoading }] =
    useFileListUpload(setMediaList);
  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      if (acceptedFiles.length === 1) {
        onFileSelected(acceptedFiles[0]);
      } else if (setMediaListUrls) {
        uploadList(acceptedFiles);
      }
    },
    [onFileSelected, uploadList, setMediaListUrls],
  );
  const { getRootProps, isDragActive, getInputProps, open } = useDropzone({
    useFsAccessApi: true,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: acceptTypes ? acceptTypes : undefined,
    onDrop,
  });
  return (
    <div
      className={css(
        styles.uploadFileSection,
        isDragActive && styles.draggingFileOverDropArea,
        styleDeclaration,
      )}
      {...getRootProps()}
    >
      <div className={css(styles.fileUploadImageContainer)}>
        {loading || listLoading ? (
          <div className={css(styles.loadingContainer)}>
            <DeprecatedIcon
              type="spinner"
              color={deprecatedTones.gray10}
              styleDeclaration={styles.spinnerContainer}
            />
            <Text type="P3">
              Uploading {pluralize("file", listLoading ? 2 : 1, "s")}...
            </Text>
          </div>
        ) : (
          <div className={css(styles.fileUploadContainerInner)}>
            <FileUploadIllustration />
            <div className={css(styles.fileUploadButtons)}>
              <Button
                variant="Outline"
                text={`Upload ${pluralize(
                  "File",
                  setMediaListUrls ? 2 : 1,
                  "s",
                )}`}
                onClick={open}
                styleDeclaration={styles.fileUploadButtonStyles}
              />
              <input {...getInputProps()} />
              <PasteLinkButton
                type="Outline"
                onAddLink={(link) => {
                  setMediaUrl(link);
                }}
                styleDeclaration={styles.pasteLinkButtonStyles}
              />
            </div>
            {description && (
              <Text
                type="P2"
                color={deprecatedTones.gray8}
                styleDeclaration={styles.descriptionStyles}
              >
                {description}
              </Text>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

type PasteLinkButtonProps = {
  onAddLink: (link: string) => void;
  type?: "Primary" | "Outline" | "No Outline";
  borderRadius?: "8px" | "40px";
  height?: "32px" | "40px";
  styleDeclaration?: StyleDeclaration;
};

export const PasteLinkButton: FC<PasteLinkButtonProps> = ({
  onAddLink,
  type,
  borderRadius,
  height,
  styleDeclaration,
}) => {
  const { addErrorToast } = useToast();
  const [menuVisible, setMenuVisible] = useState(false);
  const [link, setLink] = useState("");
  const ref = useRef(null);
  const { disableClickOutside } = useClickOutside(ref, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });
  return (
    <div className={css(styles.pasteLinkButtonContainer, styleDeclaration)}>
      <Button
        variant={type}
        borderRadius={borderRadius}
        height={height}
        text="Paste Link"
        leftIcon="link"
        onClick={() => {
          disableClickOutside();
          setMenuVisible(true);
        }}
      />
      {menuVisible && (
        // @ts-ignore-next-line
        <RelativePortal component="div" right={0} left={0} fullWidth>
          <div className={css(styles.pasteLinkMenu)} ref={ref}>
            <Text type="P3" fontWeight="SemiBold" style={{ marginBottom: 4 }}>
              Link
            </Text>
            <div className={css(styles.pasteLinkMenuRow)}>
              <TextField
                text={link}
                placeholder="Paste link here"
                onTextChange={setLink}
                containerStyleDeclaration={styles.linkFieldStyles}
              />
              <Button
                text="Add"
                disabled={!link}
                onClick={() => {
                  if (isURL(link, { protocols: ["https"] })) {
                    onAddLink(link);
                  } else {
                    addErrorToast({
                      message: "Invalid link. Please paste a valid link.",
                      callsite: "paste_media_link",
                    });
                  }
                }}
              />
            </div>
          </div>
        </RelativePortal>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  uploadFileSection: {
    width: "100%",
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `2px dashed ${deprecatedTones.gray5}`,
    borderColor: deprecatedTones.gray5Alpha,
    borderRadius: 8,
    marginBottom: 21,
    backgroundColor: deprecatedColors.surface,
  },
  draggingFileOverDropArea: {
    ...MiscStyles.focus,
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  fileUploadImageContainer: {
    height: 87,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  spinnerContainer: {
    marginBottom: 12,
  },
  fileUploadContainerInner: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  fileUploadButtons: {
    display: "flex",
    alignItems: "center",
    marginTop: 12,
  },
  descriptionStyles: {
    textAlign: "center",
    marginTop: 21,
  },
  fileUploadButtonStyles: {
    marginRight: 8,
  },
  pasteLinkButtonStyles: {
    marginLeft: 8,
  },
  pasteLinkMenuRow: {
    display: "flex",
    alignItems: "center",
  },
  pasteLinkButtonContainer: {
    position: "relative",
  },
  linkFieldStyles: {
    width: 200,
    marginRight: 8,
  },
  pasteLinkMenu: {
    position: "absolute",
    padding: "16px 15px 23px",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: 8,
    boxShadow: shadows.elevation5,
    backgroundColor: deprecatedTones.white,
    left: 0,
    zIndex: 1000,
  },
});

export default UploadFileSection;
